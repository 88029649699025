.regulator {
  height: 550px;
  pointer-events: none;
  margin-top: 180px;
  /*border: solid black 1px;*/
}

.allClockContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kld_toast-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.kld_toast-container .content {
  padding: 0.5em;
}

.radio.ui.checkbox input.hidden + label {
  color: #ffffff !important;
}

.flag-select {
  width: 100%;
}

.flag-select > button {
  background-color: white;
  color: lightgray;
  font-weight: 500;
}

.flag-select-after > button {
  background-color: white;
  color: black;
  font-weight: 500;
}

.flag-select > ul {
  background-color: white;
  color: black;
  font-weight: 500;
}

.flag-select-after > ul {
  background-color: white;
  color: black;
  font-weight: 500;
}

.ui.pointing.menu .active.item:after {
  display: none;
}

/*.allClockContainer .lightScheme .react-clock__hour-hand__body {
    background-color: white;
    box-shadow:  10px -10px 6px 1px rgba(0,0,0,0.5)
}

.allClockContainer .darkScheme .react-clock__hour-hand__body {
    background-color: black;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .redScheme .react-clock__hour-hand__body {
    background-color: red;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .blueScheme .react-clock__hour-hand__body {
    background-color: blue;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .yellowScheme .react-clock__hour-hand__body {
    background-color: yellow;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .lightScheme .react-clock__minute-hand__body {
    background-color: white;
    box-shadow:  10px -10px 6px 1px rgba(0,0,0,0.5)
}

.allClockContainer .darkScheme .react-clock__minute-hand__body {
    background-color: black;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}


.allClockContainer .blueScheme .react-clock__minute-hand__body {
    background-color: blue;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .yellowScheme .react-clock__minute-hand__body {
    background-color: yellow;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}

.allClockContainer .lightScheme .react-clock__second-hand__body {
    background-color: #f58835;
    box-shadow:  10px -10px 6px 1px rgba(0,0,0,0.5)
}

.allClockContainer .darkScheme .react-clock__second-hand__body {
    background-color: red;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
}*/

/* Mobile */

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large monitor only"]:not(.mobile),
  [class*="widescreen monitor only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}

.holder {
  width: 00px;
  height: 00px;
  background: black;
  margin: auto;
  border-radius: 0 0 50% 50%;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 6s;
  transition-timing-function: ease-in-out;
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
}

.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: opacity 6s;
  transition-timing-function: ease-in-out;
}

.show {
  opacity: 1;
}

@keyframes swing-4 {
  0%,
  100% {
    -webkit-transform: rotate(4deg);
  }

  50% {
    -webkit-transform: rotate(-4deg);
  }
}

@keyframes swing-8 {
  0%,
  100% {
    -webkit-transform: rotate(8deg);
  }

  50% {
    -webkit-transform: rotate(-8deg);
  }
}

@keyframes swing-12 {
  0%,
  100% {
    -webkit-transform: rotate(12deg);
  }

  50% {
    -webkit-transform: rotate(-12deg);
  }
}

@keyframes swing-16 {
  0%,
  100% {
    -webkit-transform: rotate(16deg);
  }

  50% {
    -webkit-transform: rotate(-16deg);
  }
}

@keyframes swing-20 {
  0%,
  100% {
    -webkit-transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
  }
}

@keyframes swing-24 {
  0%,
  100% {
    -webkit-transform: rotate(24deg);
  }

  50% {
    -webkit-transform: rotate(-24deg);
  }
}

@keyframes swing-28 {
  0%,
  100% {
    -webkit-transform: rotate(28deg);
  }

  50% {
    -webkit-transform: rotate(-28deg);
  }
}

@keyframes swing-32 {
  0%,
  100% {
    -webkit-transform: rotate(32deg);
  }

  50% {
    -webkit-transform: rotate(-32deg);
  }
}

@keyframes rotatePendulumTexture {
  0%,
  100% {
    -webkit-transform: rotate(-8deg);
  }

  50% {
    -webkit-transform: rotate(8deg);
  }
}

.gifScrollbar:hover {
  opacity: 1;
}

.gifScrollbar {
  opacity: 0.6;
}

.gifScrollbarSelected {
  opacity: 1;
}

p[data-title]::after {
  content: attr(data-title);
  background-color: white;
  color: black;
  font-size: 1em;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 70%;
  white-space: nowrap;
  box-shadow: 1px 1px 4px #242424;
  opacity: 0;
  border: 1px solid #1b1b1b;
  z-index: 9999999;
  visibility: hidden;
  font-weight: normal;
}

p[data-title]:hover::after {
  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease 0.4s;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  border-radius: 50%;
  padding: 0;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: solid 3px #000; /*change color of the swatch border here*/
  border-radius: 50%;
}
